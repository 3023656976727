<template>
  <div class="card shadow-sm">
    <form id="formItems" class="needs-validation" novalidate>
      <div class="card-header">
        <h3 class="card-title" style="font-size: 20px; font-weight: bold">
          {{ $t("addvancode") }}
        </h3>
      </div>
      <!-- form -->
      <div class="card-body">
        <!-- -->
        <div class="row d-flex justify-content-center mb-4">
          <label
            for="mainWarehouseName"
            class="required form-label col-sm-10"
            >{{ $t("vancode") }}</label
          >
          <div class="col-sm-10" style="position: relative">
            <input
              v-if="companyId == 1"
              v-model="form.van_code"
              type="text"
              maxlength="3"
              class="form-control"
              id="mainWarehouseName"
              v-mask-number
              required
            />
            <input
              v-else
              v-model="form.van_code"
              type="text"
              class="form-control"
              id="mainWarehouseName"
              required
            />
            <div class="invalid-feedback text-end">กรุณากรอกข้อมูล</div>
          </div>
          <div class="col-8 invalid-feedback text-end pe-8">
            <span>กรุณากรอก</span>
          </div>
        </div>
        <div class="row d-flex justify-content-center mb-4">
          <label
            for="mainWarehouseCode"
            class="required form-label col-sm-10"
            >{{ $t("vanname") }}</label
          >
          <div class="col-sm-10">
            <input
              v-model="form.van_name"
              type="text"
              class="form-control"
              id="mainWarehouseCode"
              required
            />
            <div class="invalid-feedback text-end">กรุณากรอกข้อมูล</div>
          </div>
        </div>
        <!-- <div class="row d-flex justify-content-center mb-4">
          <label for="validationTooltip01" class="required form-label col-sm-10"
            >ประเภทแวน</label
          >
          <div class="col-sm-10">
            <select
              :style="
                isSubmit
                  ? `border-color: #ced4da;padding-right: 0.75rem;background: none;${ccsSelect}`
                  : ''
              "
              class="form-select"
              v-model="form.van_type"
              required
            >
              <option value="คลังหลัก">คลังหลัก</option>
              <option value="คลังรอง">คลังรอง</option>
              <option value="คลังอื่นๆ">คลังอื่นๆ</option>
            </select>
          </div>
        </div> -->
        <div class="row d-flex justify-content-center mb-4">
          <label
            for="validationTooltip01"
            class="form-label col-sm-10 required"
            >{{ $t("selectbranch") }}</label
          >
          <div class="col-sm-10">
            <select
              :style="
                isSubmit
                  ? `border-color: #ced4da;padding-right: 0.75rem;background: none;${ccsSelect}`
                  : ''
              "
              class="form-select"
              v-model="form.branchId"
            >
              <option v-for="item in branches" :key="item" :value="item.id">
                {{ item.code }} |
                {{ item.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="row d-flex justify-content-center mb-4">
          <label for="validationTooltip01" class="form-label col-sm-10 required">{{
            $t("business_type")
          }}</label>
          <div class="col-sm-10">
            <select
              :style="
                isSubmit
                  ? `border-color: #ced4da;padding-right: 0.75rem;background: none;${ccsSelect}`
                  : ''
              "
              class="form-select"
              v-model="form.business_type"
            >
              <option value="Pre order">Pre order</option>
              <option value="Cash Van">Cash Van</option>
            </select>
          </div>
        </div>
      </div>

      <hr class="pt-0 mt-0" style="color: LightGrey" />

      <footer class="m-6">
        <div class="card-toolbar row justify-content-center">
          <div class="col-sm-10">
            <button
              type="button"
              class="btn btn-sm btn-light btn-primary ms-3 me-2"
              style="font-size: 14px; font-weight: bold"
              @click="submit()"
              :disabled="loading"
            >
              <div
                v-if="loading"
                class="spinner-grow spinner-grow-sm text-light"
                role="status"
              ></div>
              {{ $t("save") }}
            </button>
            <button
              type="button"
              class="btn btn-sm btn-light"
              style="font-size: 14px; font-weight: bold"
              @click="cancel"
            >
              {{ $t("cancel") }}
            </button>
          </div>
        </div>
      </footer>
    </form>
  </div>
</template>

<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import whApi from "@/api/warehouse/";

export default {
  setup() {
    document.title = "J&N | เพิ่มกลุ่มสินค้าหลัก";
  },
  data: () => ({
    loading: false,
    form: {
      // status: "1",
    },
    isSubmit: false,
    warehouses: [],
    branches: [],
    companyId: "",
  }),
  created() {
    this.companyId = localStorage.getItem("companyId");
    this.getAllBranch();
  },
  methods: {
    async getAllBranch(method) {
      this.loading = true;
      let responseData = [];
      try {
        responseData = await whApi.branch.getAll(
          localStorage.getItem("companyId")
        );
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        this.branches = responseData.data;
        this.loading = false;
      } else {
        this.loading = false;
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: "ดึงข้อมูลไม่สำเร็จ กรุณาตรวจสอบ SERVER",
        });
      }
    },
    async submit() {
      Swal.fire({
        icon: "question",
        title: `${this.$t("want2submit")} ${this.$t("ornot")} ?`,
        confirmButtonText: `${this.$t("ok")} `,
        cancelButtonText: `${this.$t("cancel")} `,
        showCancelButton: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          let createResponse = [];

          await this.validateForm();
          await this.checkFormIsEmpty();

          if (this.isValid) {
            this.loading = true;

            this.form = {
              ...this.form,
              companyId: parseInt(localStorage.getItem("companyId")),
            };

            createResponse = await whApi.van.create(this.form);
            if (createResponse.response_status === "SUCCESS") {
              Swal.fire({
                icon: "success",
                title: `${this.$t("addsuccess")} `,
                showConfirmButton: false,
                timer: 2300,
              }).then(() => {
                this.$router.push({
                  path: "/warehouse/VAN",
                  query: {},
                });
              });
              this.loading = false;
            } else {
              Swal.fire({
                icon: "error",
                title: `${createResponse.message}`,
                showConfirmButton: false,
                timer: 1500,
              });
              this.loading = false;
            }
          }
        }
      });
    },
    checkFormIsEmpty() {
      !this.form.van_name
        ? (this.isValid = false)
        : !this.form.van_code
        ? (this.isValid = false)
        : !this.form.branchId
        ? (this.isValid = false)
        : !this.form.business_type
        ? (this.isValid = false)
        : (this.isValid = true);
    },
    validateForm() {
      let forms = document.querySelectorAll(".needs-validation");
      Array.prototype.slice.call(forms).forEach(function (form) {
        form.addEventListener(
          "submit",
          function (event) {
            event.preventDefault();
            if (!form.checkValidity()) {
              event.stopPropagation();
            }
            form.classList.add("was-validated");
          },
          false
        );
      });
    },
    cancel() {
      this.$router.push({
        path: "/warehouse/VAN",
        query: {},
      });
    },
  },
};
</script>
